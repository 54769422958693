import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#2196F3',
        secondary: '#1f7ac3',
        accent: '#2196F3',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#fb8c00',
        'status-draft': colors.grey.lighten2,
        'status-pending': colors.blue.accent1,
        'status-settled': colors.green.base,
        'status-cancelled': colors.shades.black,
        'invoice-overdue': colors.red.darken1,
        'revenue-vat': colors.grey.darken1,
        'revenue-total': colors.orange.darken4,
        'revenue-averaged': colors.purple.darken1,
      },
    },
  },
  lang: {
    locales: {de},
    current: 'de',
  },
  icons: {
    iconfont: 'mdi',
  },
})
