<template>
  <div class="chart-container">
    <v-fade-transition>
      <v-apex-chart
        v-if="reportData !== null"
        type="line"
        height="100%"
        :options="chartData.options"
        :series="chartData.series"
      />
    </v-fade-transition>
  </div>
</template>

<script>
  import {formatsAmounts} from '@/lib/mixins/formatsAmounts'
  import {axios} from '@/lib/axios'

  export default {
    name: 'monthly-revenue-report-view',
    mixins: [formatsAmounts],
    props: {
      summed: Boolean,
    },
    data: () => ({
      reportData: null,
    }),
    computed: {
      chartData() {
        return {
          options: {
            chart: {
              stacked: true,
              redrawOnParentResize: true,
              background: 'transparent',
              foreColor: '#bbb',
              toolbar: {
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                },
              },
            },
            grid: {
              borderColor: '#666',
            },
            xaxis: {categories: this.reportData.map(d => d.month)},
            yaxis: {
              decimalsInFloat: 2,
            },
            stroke: {
              width: [0, 0, 0],
              curve: 'smooth',
            },
            dataLabels: {
              enabled: true,
            },
            theme: {mode: 'dark'},
            colors: this.summed
              ? [
                this.$vuetify.theme.currentTheme['revenue-total'],
              ]
              : [
                this.$vuetify.theme.currentTheme['status-settled'],
                this.$vuetify.theme.currentTheme['status-pending'],
                this.$vuetify.theme.currentTheme['revenue-vat'],
              ],
          },
          series: this.summed
            ? [
              {name: 'Summe', data: this.reportData.map(d => d.total), type: 'column'},
            ]
            : [
              {name: 'Bezahlt', data: this.reportData.map(d => d.settled_revenue), type: 'column'},
              {name: 'Ausstehend', data: this.reportData.map(d => d.pending_revenue), type: 'column'},
              {name: 'Umsatzsteuer', data: this.reportData.map(d => d.vat), type: 'column'},
            ],
        }
      },
    },
    async mounted() {
      const {data} = await axios.get(`/report/monthly-revenue`)
      this.reportData = data
    },
  }
</script>

<style lang="scss" scoped>
  .chart-container {
    height: 300px;
  }
</style>
