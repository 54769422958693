<template>
  <v-container v-if="loaded">
    <v-card :disabled="loading" class="pa-3">
      <v-row dense>
        <v-col cols="12" md="8">
          <v-text-field v-model="invoiceTemplate.title" label="Vorlagenname" />
        </v-col>
        <v-col cols="12" md="4">
          <v-row no-gutters>
            <v-col cols="6" md="12">
              <v-radio-group v-model="invoiceTemplate.type">
                <v-radio value="service" label="Dienstleistung" />
                <v-radio value="items" label="Ware" />
              </v-radio-group>
            </v-col>
            <v-col cols="6" md="12">
              <v-checkbox v-model="invoiceTemplate.allow_expressions" label="Dynamische Ausdrücke erlauben" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <h3>Posten</h3>
          <invoice-template-item-editor
            v-for="(item, index) in invoiceTemplate.items"
            :key="item._transitionId"
            v-model="invoiceTemplate.items[index]"
            @delete="removeInvoiceTemplateItem(index)"
          />
          <v-btn @click="addInvoiceTemplateItem" text>
            <v-icon left>mdi-plus</v-icon>
            Hinzufügen
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-textarea label="Bemerkungen" auto-grow rows="1" v-model="invoiceTemplate.note" />
        </v-col>
      </v-row>
    </v-card>

    <v-btn color="primary" @click="save" :disabled="!hasPendingChanges">
      <template v-if="hasPendingChanges">Speichern</template>
      <template v-else>
        <v-icon left>mdi-check</v-icon>
        Gespeichert
      </template>
    </v-btn>

    <debug-view>
      <pre>{{ invoiceTemplate }}</pre>
    </debug-view>
  </v-container>
  <throttled-spinner-container v-else />
</template>

<script>
  import DebugView from '../components/debug-view'
  import ThrottledSpinnerContainer from '../components/throttled-spinner-container'
  import {format} from 'date-fns'
  import {editPageForResource} from '../lib/mixins/editPage'
  import {InvoiceTemplateItemContainer} from '../data-container/InvoiceTemplateItemContainer'
  import InvoiceTemplateItemEditor from '../components/invoice-template-item-editor'
  import {hasUndoRedoStack} from '../lib/mixins/hasUndoStack'

  const createInvoiceTemplate = () => ({
    id: null,
    title: '',
    type: 'service',
    items: [],
  })

  const createInvoiceTemplateItem = () => new InvoiceTemplateItemContainer({
    name: '',
    description: '',
    price: '',
    vat_percentage: '',
    quantity: 1,
    internalNote: '',
  })

  export default {
    name: 'invoice-template-page',
    components: {InvoiceTemplateItemEditor, ThrottledSpinnerContainer, DebugView},
    mixins: [
      editPageForResource('invoice-template'),
      hasUndoRedoStack,
    ],
    data: () => ({
      invoiceTemplate: createInvoiceTemplate(),
      attachmentFile: null,
    }),
    computed: {
      undoRedoState: {
        get() {
          return this.invoiceTemplate
        },
        set(state) {
          this.invoiceTemplate = state
        },
      },
    },
    methods: {
      addInvoiceTemplateItem() {
        this.undoredo.snapshot()
        this.invoiceTemplate.items.push(createInvoiceTemplateItem())
      },
      removeInvoiceTemplateItem(index) {
        this.undoredo.snapshot()
        this.invoiceTemplate.items.splice(index, 1)
      },
      formatDate(date) {
        if (!date) return ''
        return format(new Date(date), 'dd.MM.yyyy')
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
