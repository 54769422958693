var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('v-container',[_c('v-card',{staticClass:"pa-3",attrs:{"disabled":_vm.loading}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8","sm":"3"}},[_c('v-select',{attrs:{"items":[
            {text: 'Divers / Andere', value: 'other'},
            {text: 'Herr', value: 'male'},
            {text: 'Frau', value: 'female'},
          ],"label":"Anrede"},model:{value:(_vm.contact.gender),callback:function ($$v) {_vm.$set(_vm.contact, "gender", $$v)},expression:"contact.gender"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"1"}},[_c('v-text-field',{attrs:{"label":"Titel"},model:{value:(_vm.contact.title),callback:function ($$v) {_vm.$set(_vm.contact, "title", $$v)},expression:"contact.title"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Vorname"},model:{value:(_vm.contact.first_name),callback:function ($$v) {_vm.$set(_vm.contact, "first_name", $$v)},expression:"contact.first_name"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Nachname"},model:{value:(_vm.contact.last_name),callback:function ($$v) {_vm.$set(_vm.contact, "last_name", $$v)},expression:"contact.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Straße"},model:{value:(_vm.contact.address_street),callback:function ($$v) {_vm.$set(_vm.contact, "address_street", $$v)},expression:"contact.address_street"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Nr."},model:{value:(_vm.contact.address_no),callback:function ($$v) {_vm.$set(_vm.contact, "address_no", $$v)},expression:"contact.address_no"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"PLZ"},model:{value:(_vm.contact.address_zip),callback:function ($$v) {_vm.$set(_vm.contact, "address_zip", $$v)},expression:"contact.address_zip"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Stadt"},model:{value:(_vm.contact.address_city),callback:function ($$v) {_vm.$set(_vm.contact, "address_city", $$v)},expression:"contact.address_city"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                'Deutschland'
              ],"label":"Land"},model:{value:(_vm.contact.address_country),callback:function ($$v) {_vm.$set(_vm.contact, "address_country", $$v)},expression:"contact.address_country"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Organisation"},model:{value:(_vm.contact.company),callback:function ($$v) {_vm.$set(_vm.contact, "company", $$v)},expression:"contact.company"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Position"},model:{value:(_vm.contact.position),callback:function ($$v) {_vm.$set(_vm.contact, "position", $$v)},expression:"contact.position"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"E-Mail","append-icon":"mdi-email"},on:{"click:append":_vm.openMailtoLink},model:{value:(_vm.contact.mail),callback:function ($$v) {_vm.$set(_vm.contact, "mail", $$v)},expression:"contact.mail"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"hide-details":"","label":"Notizen (intern)"},model:{value:(_vm.contact.internal_note),callback:function ($$v) {_vm.$set(_vm.contact, "internal_note", $$v)},expression:"contact.internal_note"}})],1)],1)],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.hasPendingChanges},on:{"click":_vm.save}},[(_vm.hasPendingChanges)?[_vm._v("Speichern")]:[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Gespeichert ")]],2),_c('debug-view',[_c('pre',[_vm._v(_vm._s(_vm.contact))])])],1):_c('throttled-spinner-container')
}
var staticRenderFns = []

export { render, staticRenderFns }