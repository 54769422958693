export const hasInputValue = {
  props: ['value'],
  data: vm => ({
    inputValue: vm.value
  }),
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.inputValue = value
      },
    },
    inputValue: {
      deep: true,
      handler(inputValue) {
        this.$emit('input', inputValue)
      },
    }
  }
}
