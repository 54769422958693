<template>
  <v-container>
    <v-data-table
      :loading="loading"
      :items="invoicePlans"
      :headers="[
        {text: 'Intervall', value: 'cron_expression'},
        {text: 'Vorlage', value: 'invoice_template.title'},
        {text: 'Kunde', value: 'contact.display_name'},
        {text: 'Aktiv', value: 'enabled'},
        {text: 'Nächste Ausführung', value: 'next_date'},
      ]"
      :options="{
        sortBy: ['title'],
      }"
      @click:row="edit"
    />

    <v-btn :to="{name: 'invoice-plan/new'}">Hinzufügen</v-btn>
  </v-container>
</template>

<script>
  import {listPageForResource} from '../lib/mixins/listPage'

  export default {
    name: 'invoice-plans-page',
    mixins: [listPageForResource('invoice-plan')],
  }
</script>

<style lang="scss" scoped>

</style>
