<template>
  <div v-if="loaded">
    <v-card outlined class="future-invoices-card" :class="{tinted: futureInvoices.length > 0}">
      <div class="tint-overlay"></div>
      <div class="untinted">
        <v-card-title class="pb-1">Anstehende Rechnungen</v-card-title>
        <v-card-text v-if="futureInvoices.length === 0">
          Es gibt gerade keine anstehenden Rechnungen.
        </v-card-text>
        <template v-else>
          <v-list class="pa-0">
            <v-list-item
              v-for="invoice in futureInvoices"
              :key="invoice.id"
              :to="{name: 'invoice/edit', params: {id: invoice.id}}"
              class="py-3"
            >
              <invoice-summary-view :invoice="invoice" class="flex-grow-1" />
            </v-list-item>
          </v-list>
          <v-card-text>
            <div class="d-flex justify-end">
              <v-btn depressed :to="{name: 'invoice/list'}">
                Alle anzeigen
              </v-btn>
            </div>
          </v-card-text>
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
  import {axios} from '../lib/axios'
  import InvoiceSummaryView from './invoice-summary-view'

  export default {
    name: 'future-invoices-card',
    components: {InvoiceSummaryView},
    data: () => ({
      futureInvoices: [],
      loaded: false,
    }),
    mounted() {
      this.fetch()
    },
    methods: {
      async fetch() {
        const {data} = await axios.get('/invoice/future')

        this.futureInvoices = data
        this.loaded = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .future-invoices-card {
    transition: border-color 200ms;
    height: 100%;

    .tint-overlay {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--v-status-pending-darken4) !important;
      opacity: 0;
      transition: opacity 200ms;
      pointer-events: none;
    }

    .untinted {
      z-index: 2;
    }

    &.tinted {
      border-color: var(--v-status-pending-darken4) !important;

      .tint-overlay {
        opacity: 0.08;
      }
    }
  }
</style>
