<template>
  <v-autocomplete
    v-model="inputValue"
    :items="invoiceTemplates"
    :search-input.sync="query"
    :loading="loading"
    return-object
    item-text="title"
    v-bind="$attrs"
  />
</template>

<script>
  import {axios} from '../lib/axios'
  import throttle from 'lodash.throttle'
  import {hasInputValue} from '../lib/mixins/hasInputValue'

  export default {
    name: 'invoice-template-select',
    mixins: [hasInputValue],
    data: () => ({
      invoiceTemplates: [],
      query: '',
      loading: false,
    }),
    watch: {
      query() {
        this.throttledQuery()
      }
    },
    methods: {
      throttledQuery: throttle(async function() {
        if (this.loading) {
          return
        }

        this.loading = true

        try {
          const {data} = await axios.get('/invoice-template/search', {
            params: {
              query: this.query,
              always_include: this.inputValue?.id,
            }
          })
          this.invoiceTemplates = data
        } catch (e) {
          console.error(e)
        }

        this.loading = false
      }, 300)
    }
  }
</script>

<style scoped>

</style>
