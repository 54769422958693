import {Container} from './Container'
import colors from 'vuetify/lib/util/colors'
import {InvoiceItemContainer} from './InvoiceItemContainer'

export class InvoiceContainer extends Container {
  _init() {
    this.items = InvoiceItemContainer.wrap(this.items)
  }

  get status_color() {
    switch (this.status) {
      case 'draft':
        return colors.grey.lighten2
      case 'pending':
        return this.is_overdue
          ? colors.red.darken1
          : colors.blue.accent1
      case 'settled':
        return colors.green.base
      case 'cancelled':
        return colors.shades.black
      default:
        return colors.shades.white
    }
  }
}
