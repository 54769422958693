export const downloadBlob = (blob, filename, mime = null) => {
  // Somewhat hilarious but hm
  const url = window.URL.createObjectURL(new Blob([blob], {
    type: mime,
  }))
  const link = document.createElement('a')
  link.href = url

  if (['application/pdf', 'text/plain'].includes(mime)) {
    link.setAttribute('target', '_blank')
  } else {
    link.setAttribute('download', filename)
  }

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
