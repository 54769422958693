import Vue from 'vue'
import App from './app'
import {router} from './router'
import {store} from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './styles/global.scss'
import './registerServiceWorker'
import PortalVue from 'portal-vue'
import {isTouchDevice} from './lib/isTouchDevice'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

Vue.prototype.$app = {
  isTouch: isTouchDevice(),
}

Vue.use(PortalVue)
Vue.component('v-apex-chart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
