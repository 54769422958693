<template>
  <v-container>
    <v-data-table
      :loading="loading"
      :items="invoiceTemplates"
      :headers="[
        {text: 'Name', value: 'title'},
        {text: '', value: '_actions', width: 1},
      ]"
      :options="{
        sortBy: ['title'],
      }"
      @click:row="edit"
    >
      <template #item._actions="{item}">
        <v-btn text @click.stop="$router.push({name: 'invoice/new-from-template', params: {templateId: item.id}})">
          Erstellen
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-btn :to="{name: 'invoice-template/new'}">Hinzufügen</v-btn>
  </v-container>
</template>

<script>
  import {listPageForResource} from '@/lib/mixins/listPage'

  export default {
    name: 'invoice-templates-page',
    mixins: [listPageForResource('invoice-template')]
  }
</script>

<style lang="scss" scoped>

</style>
