<template>
  <v-container class="mt-8">
    <div>
      <div v-if="connections === null" class="text-center">
        <v-progress-circular indeterminate />
      </div>
      <template v-else>
        <template v-if="connections.length === 0">
          <div class="d-flex flex-column align-center text-center">
            <h1>N26 Konto verknüpfen</h1>
            <p class="max-width-400">
              Verknüpfe dein N26 Konto mit Taco, um Rechnungen automatisch als bezahlt markieren
              zu lassen.
            </p>
            <v-btn color="primary" @click="reset(); n26.accountDialogOpen = true">Konto verknüpfen</v-btn>
          </div>
        </template>
        <template v-else>
          <bank-connection-view
            v-for="connection in connections"
            :key="connection.id"
            :connection="connection"
            :loading="importing"
            @delete="deleteConnection(connection.id)"
            @import="startN26ImportFlow(connection)"
          />
        </template>
      </template>
    </div>

    <div class="or-separator py-12">
      <span>oder Datei importieren</span>
    </div>

    <div>
      <v-row dense>
        <v-col cols="12" md="5" lg="3">
          <v-select
            outlined
            v-model="fileImportType"
            label="Importtyp"
            placeholder="Importtyp auswählen"
            :items="[
            {text: 'BLSK (Sparkasse), CAMT', value: 'blsk_camt'},
            {text: 'N26, CSV', value: 'n26_csv'},
          ]"
            @change="reset"
          />
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-file-input
            outlined
            v-model="file"
            @change="reset"
            label="Datei"
            placeholder="Datei auswählen"
            truncate-length="64"
            :prepend-icon="null"
            prepend-inner-icon="mdi-paperclip"
          />
        </v-col>
      </v-row>
      <v-alert v-if="fileImportType === 'blsk_camt'" type="info" dark text color="white">
        Du kannst deine Umsätze aus deinem Bankkonto
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.blsk.de/de/home/onlinebanking/umsaetze/umsaetze.html"
        >im Onlinebanking deiner Sparkasse</a>
        exportieren. Als Format muss "CAMT-Format (gebuchte Umsätze)" gewählt werden.
      </v-alert>
      <v-alert v-else-if="fileImportType === 'n26_csv'" type="info" dark text color="white">
        Du kannst deine Umsätze aus deinem Bankkonto
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://app.n26.com/downloads"
        >in der N26 Web App als CSV herunterladen</a>.
      </v-alert>
    </div>

    <v-dialog v-model="n26.accountDialogOpen" :persistent="n26.challenge !== 'login'" max-width="400">
      <v-card class="overflow-hidden">
        <v-window :value="n26ChallengeIndex">
          <v-window-item :value="0">
            <div class="px-6 pt-3">

              <svg class="n26-logo py-8" viewBox="0 0 94.01 49.36" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.04 15.4l17.94 26.49h3.29V7.37h-3.99v26.25L3.91 8.02l-.44-.65H1.59l-.03-.04v.04H.05v34.52h3.99V15.4zm47.51 12.68C57.62 24.4 60 21.24 60 16.79c0-6.11-4.13-10.06-10.5-10.06A12.14 12.14 0 0 0 38.38 14l-.6 1.34L41.39 17l.61-1.29a8.15 8.15 0 0 1 7.48-5c4 0 6.45 2.28 6.45 6.11 0 2.46-1.07 4.64-6.37 7.9-9.46 5.8-11.55 8.39-11.8 14.6v2.6h22.13v-4h-18c.36-3.18 1.76-5.03 9.66-9.84zm30.95-8.64a14.06 14.06 0 0 0-8.22 3c.62-7.44 3.9-11.8 9-11.8a8.4 8.4 0 0 1 6.07 2.41l1.14 1L93.08 11l-1.14-1a12.61 12.61 0 0 0-8.67-3.29c-8.07 0-13.08 7.07-13.08 18.45 0 12.79 6.34 17.35 12.27 17.35C90 42.53 94 36.55 94 30.64c0-8.27-6.19-11.2-11.5-11.2zm7.5 11.2c0 3.95-2.58 7.94-7.51 7.94-3.35 0-7.47-2.85-8.2-10.85 1.45-1.74 4.58-4.35 8.24-4.35 6.71.01 7.47 5.08 7.47 7.26zM0 46.39h25.32v2.97H0zM.09 0h25.23v2.97H.09z"
                  fill="currentColor"
                />
              </svg>

              <v-text-field
                v-if="!n26.isImportFlow"
                v-model="n26.username"
                :autofocus="!$app.isTouch"
                :error-messages="n26.loginErrors"
                hide-details
                placeholder="E-Mail Adresse"
                prepend-icon="mdi-account"
                @keyup.enter="n26Login"
              />

              <div v-else class="text-center">
                <strong>{{ n26.currentConnection.username }}</strong>
              </div>

              <v-text-field
                v-model="n26.password"
                :error-messages="n26.loginErrors"
                placeholder="Passwort"
                prepend-icon="mdi-key"
                type="password"
                ref="n26PasswordField"
                @keyup.enter="n26Login"
              />
            </div>

            <v-btn :loading="n26.loading" block color="primary" depressed tile @click="n26Login">
              Weiter
            </v-btn>
          </v-window-item>
          <v-window-item :value="1">
            <div class="px-6 py-8">
              <div class="text-center pb-8">
                <v-icon size="96">
                  mdi-cellphone-text
                </v-icon>
              </div>
              <p class="text-center">
                Bitte bestätige die Anmeldung auf deinem Smartphone
              </p>
              <v-progress-linear indeterminate />
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div class="px-6 py-8">
              <h2>Fast geschafft</h2>
              <p>Auf welchem Space gehen die Zahlungen ein?</p>
              <v-radio-group v-model="n26.selectedSpaceAccountId">
                <v-radio
                  v-for="space in n26.spaces"
                  :key="space.account_id"
                  :label="space.name"
                  :value="space.account_id"
                />
              </v-radio-group>
            </div>

            <v-btn
              :loading="n26.loading"
              :disabled="!n26.selectedSpaceAccountId"
              block
              color="primary"
              depressed
              tile
              @click="n26SetAccountId"
            >
              Fertig
            </v-btn>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>

    <v-dialog :value="changes !== null && changes.length > 0" persistent max-width="600">
      <v-card>
        <v-list flat v-if="changes !== null">
          <v-list-item-group v-model="selectedHashes" multiple>
            <v-list-item v-for="(change, index) in changes" :key="index" :value="change.transaction.hash">
              <template v-slot:default="{active, toggle}">
                <v-list-item-action>
                  <v-checkbox v-model="/* eslint-disable vue/valid-v-model */ active" @click="toggle" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Rechnung {{ change.invoice.reference }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ formatAmount(change.transaction.amount) }} bezahlt am {{ formatDate(change.transaction.date) }}
                    von {{ change.transaction.from }}.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="d-flex pa-5">
          <v-spacer />
          <v-btn
            :disabled="importing"
            @click="changes = null"
            depressed
            class="mr-1"
          >
            Abbrechen
          </v-btn>
          <v-btn
            :loading="importing"
            :disabled="changes === null || selectedHashes.length === 0"
            color="primary"
            depressed
            @click="runImport(true)"
          >
            Importieren
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-btn
      :loading="importing"
      :disabled="file == null || importType == null"
      @click="startImportFlow('file')"
      color="primary"
    >
      Weiter
    </v-btn>

    <v-snackbar v-model="noChangesDetectedSnackbarOpen" color="success" top right :timeout="4000">
      Keine Datensätze zum Import verfügbar.
    </v-snackbar>
  </v-container>
</template>

<script>
  import {axios} from '../../lib/axios'
  import {formatsDates} from '../../lib/mixins/formatsDates'
  import {formatsAmounts} from '../../lib/mixins/formatsAmounts'
  import BankConnectionView from '../../components/bank-connection-view'

  const getDefaultN26State = () => ({
    currentConnection: null,
    mfaCheckIntervalId: null,
    loading: false,
    accountDialogOpen: false,
    challenge: 'login',
    isImportFlow: false, // false when adding a new account, true when importing
    username: '',
    password: '',
    loginErrors: [],
    spaces: [],
    selectedSpaceAccountId: null,
  })

  export default {
    name: 'manual-import-page',
    components: {BankConnectionView},
    mixins: [formatsDates, formatsAmounts],
    data: () => ({
      importType: null, // 'file' or 'n26'
      fileImportType: null, // 'blsk_camt', 'n26_csv' etc.
      file: null,
      changes: null,
      selectedHashes: [],
      importing: false,
      connections: null,
      noChangesDetectedSnackbarOpen: false,
      n26: getDefaultN26State(),
    }),
    computed: {
      n26ChallengeIndex() {
        switch (this.n26.challenge) {
          case 'login':
            return 0
          case 'mfa':
            return 1
          case 'select_space':
            return 2
          default:
            return 0
        }
      },
    },
    mounted() {
      this.fetchConnections()
    },
    methods: {
      async startImportFlow(type, reset = true) {
        if (reset) {
          this.reset()
        }
        this.importType = type
        await this.runImport(false)
      },
      async runImport(apply = false) {
        this.importing = true

        try {
          let changes

          switch (this.importType) {
            case 'file': {
              const formData = new FormData()
              formData.append('file', this.file)
              formData.append('import_type', this.fileImportType)
              formData.append('apply', apply ? '1' : '0')

              if (apply) {
                for (const selectedHash of this.selectedHashes) {
                  formData.append('include[]', selectedHash)
                }
              }

              changes = await axios.$post('/import/file', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            } break
            case 'n26': {
              changes = await axios.$post(
                '/import/n26',
                {
                  connection_id: this.n26.currentConnection.id,
                  apply,
                  include: this.selectedHashes,
                },
              )
            } break
          }

          this.changes = changes
          this.selectedHashes = changes.map(change => change.transaction.hash)

          if (!apply && this.changes.length === 0) {
            this.noChangesDetectedSnackbarOpen = true
          }
        } finally {
          this.importing = false
        }
      },
      async startN26ImportFlow(connection) {
        this.reset()
        this.n26.currentConnection = connection
        this.n26.isImportFlow = true
        this.importing = true

        try {
          const fetchedConnection = await axios.$get(`/import/n26/connection/${this.n26.currentConnection.id}`)

          if (fetchedConnection.is_ready) {
            await this.startImportFlow('n26', false)
          } else {
            this.n26.accountDialogOpen = true

            setTimeout(() => {
              this.$refs.n26PasswordField.focus()
            }, 100)
          }
        } catch (e) {
          console.error(e)
        }

        this.importing = false
      },
      reset() {
        this.changes = null
        this.selectedHashes = []
        this.n26 = getDefaultN26State()
      },

      // N26
      async fetchConnections() {
        this.connections = await axios.$get('/import/n26/connection')
      },
      async n26Login() {
        if (this.n26.loading) {
          return
        }

        this.n26.loading = true

        try {
          let connection
          if (this.n26.isImportFlow) {
            connection = await axios.$post(`/import/n26/connection/${this.n26.currentConnection.id}/login`, {
              password: this.n26.password,
            })
          } else {
            connection = await axios.$post('/import/n26/connection', {
              username: this.n26.username,
              password: this.n26.password,
            })
          }

          this.n26.currentConnection = connection

          const mfaCheckHandler = async () => {
            this.n26.mfaCheckTimeoutId = null

            try {
              const checkResult = await axios.$get(`/import/n26/connection/${connection.id}/mfa-challenge`)

              if (checkResult.completed) {
                if (this.n26.isImportFlow) {
                  this.startImportFlow('n26', false)
                  this.n26.accountDialogOpen = false
                } else {
                  this.n26.spaces = await axios.$get(`/import/n26/connection/${connection.id}/spaces`)
                  this.n26.challenge = 'select_space'
                }
              } else {
                this.n26.mfaCheckTimeoutId = setTimeout(mfaCheckHandler, 3000)
              }
            } catch (e) {
              console.error(e)
            }
          }

          this.n26.mfaCheckTimeoutId = setTimeout(mfaCheckHandler, 3000)
          this.n26.challenge = 'mfa'
        } catch (e) {
          console.error(e)

          if (e.response?.status === 429) {
            this.n26.loginErrors = [`Zu viele Versuche. Bitte probiere es wieder nach ${e.response?.headers['retry-after'] ?? '-'}`]
          } else {
            this.n26.loginErrors = ['Die Anmeldung konnte nicht durchgeführt werden']
          }
        }

        this.n26.loading = false
      },
      async n26SetAccountId() {
        if (this.n26.loading) {
          return
        }

        this.n26.loading = true

        try {
          await axios.put(`/import/n26/connection/${this.n26.currentConnection.id}`, {
            account_id: this.n26.selectedSpaceAccountId,
          })

          await this.fetchConnections()

          this.n26.accountDialogOpen = false

          await this.$nextTick()
          this.n26 = getDefaultN26State()
        } catch (e) {
          console.error(e)
        }

        this.n26.loading = false
      },
      async deleteConnection(id) {
        if (confirm('Möchtest du die Verknüpfung wirklich aufheben?')) {
          await axios.delete(`/import/n26/connection/${id}`)
          await this.fetchConnections()
        }
      },
    },
    beforeDestroy() {
      if (this.n26.mfaCheckTimeoutId) {
        clearTimeout(this.n26.mfaCheckTimeoutId)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .or-separator {
    position: relative;
    text-align: center;

    span {
      color: #8d8d8d;
      background: #121212;
      position: relative;
      padding: 0 1em;
    }

    &::before {
      content: '';
      display: block;
      background: #3d3d3d;
      width: 100%;
      height: 1px;
      position: absolute;
      top: calc(50% - 1px);
    }
  }

  .n26-logo {
    display: block;
    margin: 0 auto;
    max-width: 128px;
  }
</style>
