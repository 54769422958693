var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.invoicePlans,"headers":[
      {text: 'Intervall', value: 'cron_expression'},
      {text: 'Vorlage', value: 'invoice_template.title'},
      {text: 'Kunde', value: 'contact.display_name'},
      {text: 'Aktiv', value: 'enabled'},
      {text: 'Nächste Ausführung', value: 'next_date'},
    ],"options":{
      sortBy: ['title'],
    }},on:{"click:row":_vm.edit}}),_c('v-btn',{attrs:{"to":{name: 'invoice-plan/new'}}},[_vm._v("Hinzufügen")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }