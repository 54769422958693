<template>
  <v-app>
    <v-navigation-drawer app v-model="drawerOpen" v-if="!$route.meta.hideNavigation">
      <v-toolbar tile color="primary" flat>
        <router-link class="d-block flex-grow-1 text-center" :to="{name: 'home'}">
          <h1 class="white--text">Taco</h1>
        </router-link>
      </v-toolbar>
      <v-list dense nav>
        <v-list-item v-for="item in drawerItems" :key="item.routeName" :to="{name: item.routeName}" link>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app :value="$store.getters.isLoggedIn && !$route.meta.hideNavigation">
      <v-app-bar-nav-icon @click.stop="drawerOpen = !drawerOpen" />
      <v-toolbar-title>{{ $route.meta.title || '' }}</v-toolbar-title>
      <v-spacer />
      <portal-target name="appBarInlineEnd" />
      <template v-if="!!$route.meta.hasTabs" v-slot:extension>
        <portal-target name="tabs" slim />
      </template>
    </v-app-bar>
    <v-main>
      <transition name="page-transition" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
  import {axios} from './lib/axios'

  export default {
    name: 'app',
    data: vm => ({
      drawerOpen: vm.$store.getters.isLoggedIn && vm.$vuetify.breakpoint.lgAndUp,
      drawerItems: [
        {routeName: 'invoice/list', icon: 'mdi-text-box-multiple-outline', label: 'Rechnungen'},
        {routeName: 'contact/list', icon: 'mdi-account-multiple-outline', label: 'Kontakte'},
        {routeName: 'invoice-template/list', icon: 'mdi-file-edit-outline', label: 'Vorlagen'},
        {routeName: 'invoice-plan/list', icon: 'mdi-timeline-clock-outline', label: 'Pläne'},
        {routeName: 'import', icon: 'mdi-bank-transfer', label: 'Bankimport'},
        {routeName: 'reports', icon: 'mdi-file-chart', label: 'Berichte'},
        {routeName: 'settings/security', icon: 'mdi-cog-outline', label: 'Einstellungen'},
      ],
      sessionTouchIntervalId: null,
    }),
    watch: {
      '$store.getters.isLoggedIn'(isLoggedIn) {
        this.drawerOpen = isLoggedIn && this.$vuetify.breakpoint.lgAndUp

        if (isLoggedIn) {
          this.setupNotifications()
          this.sessionTouchIntervalId = setInterval(async () => {
            await axios.post('/auth/session/touch')
          }, 1000 * 60 * 10) // 10 mins
        } else {
          clearInterval(this.sessionTouchIntervalId)
        }
      },
    },
    methods: {
      async setupNotifications() {
        if (await Notification.requestPermission() === 'granted') {
          const registration = await navigator.serviceWorker.ready
          const {data: vapid} = await axios.get('/user/push-notifications/vapid')

          const options = {
            userVisibleOnly: true,
            applicationServerKey: vapid.public_key,
          }

          const subscription = await registration.pushManager.subscribe(options)
          await this.updatePushSubscription(subscription)
        }
      },
      async updatePushSubscription(subscription) {
        const key = subscription.getKey('p256dh')
        const token = subscription.getKey('auth')
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

        await axios.post('/user/push-notifications', {
          endpoint: subscription.endpoint,
          public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
          auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
          content_encoding: contentEncoding,
        })
      },
    },
  }
</script>
