<template>
  <div class="d-flex align-center invoice-plan-summary-view">
    <div>
      <div class="title">{{ plan.invoice_template.title }}</div>
      <div class="body-2">
        {{ plan.contact.display_name }}
      </div>
    </div>
    <v-spacer />
    <div class="text-right flex-shrink-0 pl-2">
      <div class="body-2 semi-transparent">wird gesendet</div>
      <div v-if="plan.schedule_days_before > 0" class="body-1">am {{ formatDate(scheduledDate, 'EE, dd.MM.') }}</div>
      <div v-else class="body-1">sofort</div>
    </div>
  </div>
</template>

<script>
  import {formatsDates} from '../lib/mixins/formatsDates'
  import {parseISO, addDays} from 'date-fns'

  export default {
    name: 'invoice-plan-summary-view',
    mixins: [formatsDates],
    props: {
      plan: Object
    },
    computed: {
      scheduledDate() {
        return addDays(parseISO(this.plan.next_date), this.plan.schedule_days_before)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .invoice-plan-summary-view {
    max-width: 100%;

    * {
      line-height: 1.2 !important;
    }

    .semi-transparent {
      opacity: 0.6;
    }
  }
</style>
