import cloneDeep from 'lodash.clonedeep'

class StateSnapshot {
  constructor(state) {
    this.state = cloneDeep(state)
  }
}

class UndoRedoStack {
  constructor(vm) {
    this.stack = []
    this.stackCursor = -1
    this.vm = vm
    this.isDirty = false
  }

  snapshot() {
    if (this.stackCursor < this.stack.length - 1) {
      this.stack.splice(this.stackCursor)
    }

    this.stack.push(new StateSnapshot(this.vm.undoRedoState))
    this.stackCursor = this.stack.length - 1
    this.isDirty = true
  }

  clear() {
    this.stack = []
    this.stackCursor = -1
    this.isDirty = false
  }

  undo() {
    if (this.stackCursor > 0 || (this.stackCursor === 0 && this.isDirty)) {
      if (this.isDirty) {
        this.snapshot()
      }

      this.vm.undoRedoState = this.stack[--this.stackCursor].state

      this.isDirty = false

      return true
    }

    return false
  }

  redo() {
    if (this.stackCursor + 1 < this.stack.length && this.stack.length > 0) {
      this.vm.undoRedoState = this.stack[++this.stackCursor].state

      return true
    }

    return false
  }

  keydownHandler(event) {
    if (event.ctrlKey && event.key.toLowerCase() === 'z') {
      if (event.shiftKey) {
        this.redo() && event.preventDefault()
      } else {
        this.undo() && event.preventDefault()
      }
    }
  }
}

export const hasUndoRedoStack = {
  created() {
    this.undoredo = new UndoRedoStack(this)
  },
  mounted() {
    document.addEventListener('keydown', event => this.undoredo.keydownHandler(event))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', event => this.undoredo.keydownHandler(event))
  },
}
