<template>
  <v-container v-if="loaded">
    <portal to="appBarInlineEnd">
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="sendInvoice" :disabled="invoice.status === 'draft'">
            <v-list-item-title>Rechnung erneut senden</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportInvoicePdf" :disabled="!invoice.id">
            <v-list-item-title>PDF exportieren</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="deleteConfirmationDialogOpen = true"
            :disabled="!invoice.id || invoice.status !== 'draft'"
          >
            <v-list-item-title>Löschen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </portal>

    <v-card :disabled="loading" class="pa-3">
      <v-row dense>
        <v-col cols="10" md="3">
          <h1>
            {{ invoice.reference || 'Entwurf' }}
            <v-tooltip v-if="invoice.is_scheduled" bottom>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" size="36" class="ml-2">mdi-calendar-clock</v-icon>
              </template>
              <div class="tooltip-300">
                Dieser Entwurf ist geplant und wird am Rechnungsdatum automatisch
                versendet und als ausstehend markiert.
              </div>
            </v-tooltip>
          </h1>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <contact-select v-model="invoice.contact" />
        </v-col>
        <v-col cols="6" sm="3">
          <v-radio-group v-model="invoice.type">
            <v-radio value="service" label="Dienstleistung" />
            <v-radio value="items" label="Ware" />
          </v-radio-group>
        </v-col>
        <v-col cols="6" sm="3">
          <v-radio-group v-model="invoice.status">
            <v-radio value="draft" label="Entwurf" />
            <v-radio value="pending" label="Ausstehend" />
            <v-radio value="settled" label="Ausgeglichen" />
            <v-radio value="cancelled" label="Storniert" />
          </v-radio-group>
        </v-col>

        <v-expand-transition>
          <v-col v-if="invoice.is_overdue && invoice.status === 'pending'" cols="12">
            <v-alert color="warning" text outlined>
              <h3>Die Rechnung ist überfällig.</h3>
              <p>
                Bitte überprüfen Sie erneut den Zahlungseingang und wählen Sie danach eine der folgenden Optionen:
              </p>

              <v-btn outlined class="mr-1" @click="extendInvoice">
                Zahlungserinnerung senden
              </v-btn>
              <v-btn text @click="invoice.status = 'settled'">
                Die Zahlung ist eingegangen
              </v-btn>
            </v-alert>
          </v-col>
        </v-expand-transition>

        <v-col cols="6">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="0"
            :disabled="!wasDraft"
          >
            <template v-slot:activator="{on}">
              <v-text-field
                :value="formatDate(invoice.invoiced_at)"
                label="Rechnungsdatum"
                readonly
                :disabled="!wasDraft"
                v-on="on"
              />
            </template>
            <template v-slot:default>
              <v-date-picker v-model="invoice.invoiced_at" />
            </template>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="0"
            :disabled="!wasDraft"
          >
            <template v-slot:activator="{on}">
              <v-text-field
                :value="formatDate(invoice.due_at)"
                label="Zahlungsfrist"
                readonly
                :disabled="!wasDraft"
                v-on="on"
                :placeholder="`(+${$store.state.business.default_due_at_interval.description})`"
                clearable
              />
            </template>
            <template v-slot:default>
              <v-date-picker v-model="invoice.due_at" />
            </template>
          </v-menu>
        </v-col>

        <v-col cols="12">
          <h3>Posten</h3>
          <invoice-item-editor
            v-for="(item, index) in invoice.items"
            :key="item._transitionId"
            v-model="invoice.items[index]"
            :editable="canEdit"
            @delete="removeInvoiceItem(index)"
          />
          <div class="d-flex flex-wrap">
            <v-btn v-if="canEdit" @click="addInvoiceItem" text>
              <v-icon left>mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
            <v-spacer />
            <div class="flex-grow-1 text-right">
              <span>Gesamt:</span> <span class="title">{{ formatAmount(totalPrice) }}</span>
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <v-textarea label="Bemerkungen" auto-grow rows="1" v-model="invoice.note" />
        </v-col>

        <v-col cols="12">
          <h3>Anhänge</h3>

          <div>
            <invoice-attachment-view
              v-for="attachment in invoice.attachments"
              :key="attachment.id"
              :invoice-attachment="attachment"
              :editable="canEdit"
              @delete="deleteAttachment(attachment)"
            />
          </div>

          <span v-if="invoice.id === null">
            Speichern Sie die Rechnung, um Anhänge hinzuzufügen
          </span>
          <template v-else-if="canEdit">
            <input @input="onFileSelected" type="file" class="hidden-file-input" ref="fileInput" />
            <v-btn @click="openFileInput" text>
              <v-icon left>mdi-plus</v-icon>
              Hinzufügen
            </v-btn>
          </template>
          <span v-else-if="invoice.attachments.length === 0">
            Keine Anhänge hinzugefügt
          </span>
        </v-col>
      </v-row>
    </v-card>

    <v-btn color="primary" @click="onSave" :disabled="!hasPendingChanges">
      <template v-if="hasPendingChanges">Speichern</template>
      <template v-else>
        <v-icon left>mdi-check</v-icon>
        Gespeichert
      </template>
    </v-btn>

    <v-dialog v-model="publishConfirmationDialogOpen" max-width="400">
      <v-card>
        <v-card-title>Rechnung wird versendet</v-card-title>
        <v-card-text>
          <p>
            Wenn du diese Rechnung speicherst, wird sie versendet und archiviert. Du kannst
            sie danach nicht mehr löschen.<br>
            <strong>Dieser Vorgang kann nicht rückgängig gemacht werden!</strong>
          </p>
          <div class="d-flex justify-end">
            <v-btn depressed class="mr-2" @click="publishConfirmationDialogOpen = false">
              Abbrechen
            </v-btn>
            <v-btn depressed color="primary" @click="save(); publishConfirmationDialogOpen = false">
              Senden
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteConfirmationDialogOpen" :persistent="loading" max-width="400">
      <v-card>
        <v-card-title>Rechnung löschen</v-card-title>
        <v-card-text>
          <p>
            Diese Rechnung inklusive aller Anhänge wird gelöscht.<br>
            <strong>Dieser Vorgang kann nicht rückgängig gemacht werden!</strong>
          </p>
          <div class="d-flex justify-end">
            <v-btn depressed class="mr-2" @click="deleteConfirmationDialogOpen = false" :disabled="loading">
              Abbrechen
            </v-btn>
            <v-btn depressed color="error" @click="deleteInvoice" :loading="loading">
              Löschen
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog :value="!!attachmentFile" :persistent="loading" max-width="600">
      <v-card>
        <v-card-title>Anhang hinzufügen</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-avatar color="secondary" class="mr-3">
              <v-icon>mdi-file</v-icon>
            </v-avatar>
            <v-text-field v-model="attachmentFilename" ref="attachmentFilenameTextField" label="Dateiname" />
          </div>
          <div class="d-flex justify-end">
            <v-btn depressed class="mr-2" @click="attachmentFile = null" :disabled="loading">
              Abbrechen
            </v-btn>
            <v-btn depressed color="primary" @click="uploadAttachment" :loading="loading">
              Hochladen
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar top right :color="snackbarColor" v-model="snackbarOpen">
      {{ snackbarText }}
    </v-snackbar>

    <debug-view>
      <pre>{{ invoice }}</pre>
    </debug-view>
  </v-container>
  <throttled-spinner-container v-else />
</template>

<script>
  import DebugView from '../components/debug-view'
  import ThrottledSpinnerContainer from '../components/throttled-spinner-container'
  import {format, formatISO, startOfToday} from 'date-fns'
  import {editPageForResource} from '@/lib/mixins/editPage'
  import ContactSelect from '../components/contact-select'
  import InvoiceItemEditor from '../components/invoice-item-editor'
  import {InvoiceContainer} from '@/data-container/InvoiceContainer'
  import {InvoiceItemContainer} from '@/data-container/InvoiceItemContainer'
  import InvoiceAttachmentView from '../components/invoice-attachment-view'
  import {axios} from '@/lib/axios'
  import {hasUndoRedoStack} from '@/lib/mixins/hasUndoStack'
  import {formatsAmounts} from '@/lib/mixins/formatsAmounts'

  const createInvoice = () => new InvoiceContainer({
    id: null,
    reference: '',
    type: 'service',
    status: 'draft',
    invoiced_at: formatISO(startOfToday(), {representation: 'date'}),
    due_at: null,
    contact: null,
    items: [],
    attachments: [],
  })

  const createInvoiceItem = () => new InvoiceItemContainer({
    name: '',
    description: '',
    price: '',
    vat_percentage: '',
    quantity: 1,
    internalNote: '',
  })

  export default {
    name: 'invoice-page',
    components: {InvoiceAttachmentView, InvoiceItemEditor, ContactSelect, ThrottledSpinnerContainer, DebugView},
    mixins: [
      editPageForResource('invoice'),
      hasUndoRedoStack,
      formatsAmounts,
    ],
    data: () => ({
      invoice: createInvoice(),
      attachmentFile: null,
      attachmentFilename: '',
      snackbarOpen: false,
      snackbarText: '',
      snackbarColor: 'success',
      publishConfirmationDialogOpen: false,
      deleteConfirmationDialogOpen: false,
    }),
    computed: {
      undoRedoState: {
        get() {
          return this.invoice
        },
        set(state) {
          this.invoice = state
        },
      },
      wasDraft() {
        return this.getOriginal('status') === 'draft' || this.getOriginal() === null
      },
      canEdit() {
        return this.wasDraft
      },
      totalPrice() {
        return this.invoice.items?.reduce((acc, item) => (
          item.price * item.quantity + acc
        ), 0) ?? 0
      }
    },
    async beforeMount() {
      if (this.$route.name === 'invoice/new-from-template') {
        this.loaded = false
        console.log('Rendering invoice from template', this.$route.params.templateId)

        const {data} = await axios.get(`/invoice-template/${this.$route.params.templateId}/render`)

        console.log(data)
        this.setModel({
          ...createInvoice(),
          ...data.invoice,
          items: data.items,
        })
      }
    },
    methods: {
      addInvoiceItem() {
        this.undoredo.snapshot()
        this.invoice.items.push(createInvoiceItem())
      },
      removeInvoiceItem(index) {
        this.undoredo.snapshot()
        this.invoice.items.splice(index, 1)
      },
      formatDate(date) {
        if (!date) return ''
        return format(new Date(date), 'dd.MM.yyyy')
      },
      openFileInput() {
        this.$refs.fileInput.click()
      },
      async onFileSelected() {
        if (this.$refs.fileInput.files.length > 0) {
          this.attachmentFile = this.$refs.fileInput.files[0]
          this.attachmentFilename = this.$refs.fileInput.files[0].name
          this.$refs.fileInput.value = null

          await this.$nextTick()
          const input = this.$refs.attachmentFilenameTextField.$el.querySelector('input')
          input.selectionStart = 0
          input.selectionEnd = this.attachmentFilename.match(/^(?<basename>.+)\./)?.groups?.basename.length ?? 0
          await this.$nextTick()
          this.$refs.attachmentFilenameTextField.focus()
        }
      },
      async uploadAttachment() {
        this.loading = true

        try {
          const formData = new FormData()
          formData.append('file', this.attachmentFile)
          formData.append('filename', this.attachmentFilename)

          await axios.post(`/invoice/${this.invoice.id}/attachment`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          await this.load()
          this.attachmentFile = null
        } catch (e) {
          console.error(e)
        }

        this.loading = false
      },
      async deleteAttachment(attachment) {
        if (confirm('Soll der Anhang "' + attachment.filename + '" wirklich gelöscht werden?')) {
          this.loading = true
          await axios.delete(`/invoice/${this.invoice.id}/attachment/${attachment.id}`)
          await this.load()
          this.loading = false
        }
      },
      async extendInvoice() {
        if (confirm(`Es wird eine Zahlungserinnerung an ${this.invoice.contact.display_name} gesendet und das Fälligkeitsdatum um ${this.$store.state.business.default_extend_interval.description} verschoben. Fortfahren?`)) {
          this.loading = true
          const {data} = await axios.post(`/invoice/${this.invoice.id}/extend`)
          await this.setModel(data)
          this.loading = false
        }
      },
      async sendInvoice() {
        if (confirm(`Die Rechnung wird erneut an ${this.invoice.contact.display_name} (${this.invoice.contact.mail}) versandt. Fortfahren?`)) {
          try {
            await axios.post(`/invoice/${this.invoice.id}/send`)
            this.snackbarText = 'Der Rechnungsversand wurde gestartet'
            this.snackbarColor = 'success'
          } catch (e) {
            this.snackbarText = `Die Rechnung konnte nicht erneut gesendet werden (${e.response.status})`
            this.snackbarColor = 'error'
            console.error(e)
          }

          this.snackbarOpen = true
        }
      },
      exportInvoicePdf() {
        const pdfWindow = window.open()

        pdfWindow.document.documentElement.innerHTML = 'PDF wird geladen...'

        const loadPdf = async () => {
          try {
            const {data} = await axios.get(`/invoice/${this.invoice.id}/export/pdf`, {
              responseType: 'blob',
            })

            pdfWindow.location.href = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))
          } catch (e) {
            console.error(e)
            pdfWindow.document.documentElement.innerHTML = 'Ein Fehler ist aufgetreten.'
          }
        }
        loadPdf()
      },
      onSave() {
        if ((this.invoice._original?.status === 'draft' || !this.invoice._original) && this.invoice.status !== 'draft') {
          this.publishConfirmationDialogOpen = true
        } else {
          this.save()
        }
      },
      async deleteInvoice() {
        this.loading = true

        try {
          await axios.delete(`/invoice/${this.invoice.id}`)
          this.$router.replace({name: 'invoice/list'})
        } catch (e) {
          console.error(e)
        }

        this.loading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .hidden-file-input {
    display: none;
  }

  .tooltip-300 {
    max-width: 300px;
  }
</style>
