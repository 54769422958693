<template>
  <v-container>
    <v-data-table
      :loading="loading"
      :items="invoices"
      :headers="[
        {text: '#', value: 'reference'},
        {text: 'Status', value: 'status'},
        {text: 'Kunde', value: 'contact.display_name'},
        {text: 'Gesamtbetrag', value: 'total_price'},
        {text: 'Rechnungsdatum', value: 'invoiced_at'},
        {text: 'Fällig bis', value: 'due_at'},
      ]"
      :options="{
        sortBy: ['invoiced_at'],
        sortDesc: [true],
      }"
      @click:row="edit"
    >
      <template v-slot:item.total_price="{item}">
        {{ formatAmount(item.total_price) }}
      </template>
      <template v-slot:item.status="{item}">
        <v-chip small outlined :color="item.status_color">{{ item.status }}</v-chip>
      </template>
    </v-data-table>

    <v-btn :to="{name: 'invoice/new'}">Hinzufügen</v-btn>
  </v-container>
</template>

<script>
  import {listPageForResource} from '../lib/mixins/listPage'
  import {formatsAmounts} from '../lib/mixins/formatsAmounts'
  import {InvoiceContainer} from '../data-container/InvoiceContainer'

  export default {
    name: 'invoices-page',
    mixins: [listPageForResource('invoice', InvoiceContainer), formatsAmounts]
  }
</script>

<style lang="scss" scoped>

</style>
