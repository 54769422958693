<template>
  <v-container fluid>
    <div class="dashboard-grid">
      <div>
        <v-slide-y-reverse-transition>
          <overdue-invoices-card class="card-fill-height" />
        </v-slide-y-reverse-transition>
      </div>
      <div>
        <v-slide-y-reverse-transition>
          <future-invoices-card class="card-fill-height" />
        </v-slide-y-reverse-transition>
      </div>
      <div>
        <v-slide-y-reverse-transition>
          <upcoming-invoice-plans-card class="card-fill-height" />
        </v-slide-y-reverse-transition>
      </div>
    </div>
  </v-container>
</template>

<script>
  import OverdueInvoicesCard from '../components/overdue-invoices-card'
  import UpcomingInvoicePlansCard from '../components/upcoming-invoice-plans-card'
  import FutureInvoicesCard from '../components/future-invoices-card'

  export default {
    name: 'home-page',
    components: {FutureInvoicesCard, UpcomingInvoicePlansCard, OverdueInvoicesCard},
  }
</script>

<style lang="scss" scoped>
  @import '~vuetify/src/styles/styles.sass';

  $card-width: 350px;

  .dashboard-grid {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: stretch;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      grid-template-columns: 1fr 1fr;

      .card-fill-height {
        height: 100%;
      }
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      grid-template-columns: 1fr;
    }
  }
</style>
