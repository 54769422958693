<template>
  <v-container>
    <v-data-table
      :loading="loading"
      :items="contacts"
      :headers="[
        {text: 'Name', value: 'display_name'}
      ]"
      @click:row="edit"
    />

    <v-btn :to="{name: 'contact/new'}">Hinzufügen</v-btn>
  </v-container>
</template>

<script>
  import {listPageForResource} from '../lib/mixins/listPage'

  export default {
    name: 'contacts-page',
    mixins: [listPageForResource('contact')]
  }
</script>

<style lang="scss" scoped>

</style>
