import { render, staticRenderFns } from "./invoices-page.vue?vue&type=template&id=4398ce60&scoped=true"
import script from "./invoices-page.vue?vue&type=script&lang=js"
export * from "./invoices-page.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4398ce60",
  null
  
)

export default component.exports