<template>
  <v-container fluid>
    <v-card class="pa-3 mb-2">
      <v-card-title class="justify-space-between">
        Monatsumsatz

        <v-btn-toggle dense v-model="monthlyRevenueReportSummed">
          <v-btn :value="true">
            Summe
          </v-btn>
          <v-btn :value="false">
            Details
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <monthly-revenue-report-view :summed="monthlyRevenueReportSummed" />
    </v-card>
    <v-card class="pa-3">
      <v-card-title class="justify-space-between">
        Jahresumsatz

        <v-btn-toggle dense v-model="yearlyRevenueReportAveraged">
          <v-btn :value="false">
            Summe
          </v-btn>
          <v-btn :value="true">
            Monat Ø
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <yearly-revenue-report-view :averaged="yearlyRevenueReportAveraged" />
    </v-card>
  </v-container>
</template>

<script>

  import MonthlyRevenueReportView from '@/components/reports/monthly-revenue-report-view'
  import YearlyRevenueReportView from '@/components/reports/yearly-revenue-report-view'

  export default {
    name: 'reports-page',
    components: {YearlyRevenueReportView, MonthlyRevenueReportView},
    data: () => ({
      monthlyRevenueReportSummed: false,
      yearlyRevenueReportAveraged: false,
    }),
  }
</script>

<style lang="scss" scoped>

</style>
