<template>
  <v-container v-if="loaded">
    <v-card :disabled="loading" class="pa-3">
      <v-row dense>
        <v-col cols="8" sm="3">
          <v-select
            :items="[
              {text: 'Divers / Andere', value: 'other'},
              {text: 'Herr', value: 'male'},
              {text: 'Frau', value: 'female'},
            ]"
            label="Anrede"
            v-model="contact.gender"
          />
        </v-col>
        <v-col cols="4" sm="1">
          <v-text-field v-model="contact.title" label="Titel" />
        </v-col>
        <v-col cols="6" sm="4">
          <v-text-field v-model="contact.first_name" label="Vorname" />
        </v-col>
        <v-col cols="6" sm="4">
          <v-text-field v-model="contact.last_name" label="Nachname" />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-row dense>
            <v-col cols="9">
              <v-text-field hide-details v-model="contact.address_street" label="Straße" />
            </v-col>
            <v-col cols="3">
              <v-text-field hide-details v-model="contact.address_no" label="Nr." />
            </v-col>
            <v-col cols="4">
              <v-text-field hide-details v-model="contact.address_zip" label="PLZ" />
            </v-col>
            <v-col cols="8">
              <v-text-field hide-details v-model="contact.address_city" label="Stadt" />
            </v-col>
            <v-col cols="12">
              <v-select
                :items="[
                  'Deutschland'
                ]"
                label="Land"
                v-model="contact.address_country"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <v-row dense>
            <v-col cols="12">
              <v-text-field hide-details v-model="contact.company" label="Organisation" />
            </v-col>
            <v-col cols="12">
              <v-text-field hide-details v-model="contact.position" label="Position" />
            </v-col>
            <v-col cols="12">
              <v-text-field hide-details v-model="contact.mail" label="E-Mail" append-icon="mdi-email" @click:append="openMailtoLink" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-textarea hide-details v-model="contact.internal_note" label="Notizen (intern)" />
        </v-col>
      </v-row>
    </v-card>

    <v-btn color="primary" @click="save" :disabled="!hasPendingChanges">
      <template v-if="hasPendingChanges">Speichern</template>
      <template v-else>
        <v-icon left>mdi-check</v-icon>
        Gespeichert
      </template>
    </v-btn>

    <debug-view>
      <pre>{{ contact }}</pre>
    </debug-view>
  </v-container>
  <throttled-spinner-container v-else />
</template>

<script>
  import DebugView from '../components/debug-view'
  import ThrottledSpinnerContainer from '../components/throttled-spinner-container'
  import {editPageForResource} from '../lib/mixins/editPage'

  const createContact = () => ({
    id: null,
    gender: 'other',
    title: '',
    first_name: '',
    last_name: '',
    address_street: '',
    address_no: '',
    address_zip: '',
    address_city: '',
    address_country: 'Deutschland',
    company: '',
    position: '',
    mail: '',
    internal_note: '',
  })

  export default {
    name: 'contact-page',
    components: {ThrottledSpinnerContainer, DebugView},
    mixins: [editPageForResource('contact')],
    data: () => ({
      contact: createContact(),
    }),
    methods: {
      openMailtoLink() {
        window.open(`mailto:${this.contact.mail}`)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
