<template>
  <div class="invoice-item-editor">
    <v-btn v-if="editable" class="mr-3 mt-5" icon @click="$emit('delete')">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-row dense>
      <v-col cols="2" sm="1">
        <v-text-field :readonly="!editable" v-model="inputValue.quantity" type="number" label="Anz." hide-details />
      </v-col>
      <v-col cols="10" sm="7">
        <v-text-field :readonly="!editable" v-model="inputValue.name" label="Name" hide-details />
      </v-col>
      <v-col cols="6" sm="2">
        <v-text-field :readonly="!editable" v-model="inputValue.price" type="number" step=".01" label="Preis" suffix="€" hide-details />
      </v-col>
      <v-col cols="6" sm="2">
        <v-text-field :readonly="!editable" v-model="inputValue.vat_percentage" type="number" step="1" label="USt." :placeholder="String($store.state.business.default_vat_percentage)" suffix="%" hide-details />
      </v-col>
      <v-col cols="12" offset-sm="1" sm="7">
        <v-textarea :readonly="!editable" v-model="inputValue.description" rows="1" auto-grow label="Beschreibung" hide-details />
      </v-col>
      <v-col cols="12" sm="4">
        <v-textarea v-model="inputValue.internal_note" rows="1" auto-grow label="Interne Notizen" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {hasInputValue} from '../lib/mixins/hasInputValue'

  export default {
    name: 'invoice-item-editor',
    props: {
      editable: Boolean,
    },
    mixins: [hasInputValue]
  }
</script>

<style lang="scss" scoped>
  .invoice-item-editor {
    display: flex;

    .fields {
      flex-grow: 1;
    }
  }
</style>
