import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from './store'

Vue.use(VueRouter)

const routes = [

  // Generic routes
  {
    path: '/',
    name: 'home',
    meta: {title: 'Übersicht'},
    component: require('./pages/home-page').default,
  },
  {
    path: '/login',
    name: 'login',
    component: require('./pages/login-page').default,
    meta: {disableGuard: true},
  },

  // Contacts
  {
    path: '/contacts',
    name: 'contact/list',
    meta: {title: 'Kontakte'},
    component: require('./pages/contacts-page').default,
  },
  {
    path: '/contacts/new',
    name: 'contact/new',
    meta: {new: true, title: 'Neuen Kontakt anlegen'},
    component: require('./pages/contact-page').default,
  },
  {
    path: '/contacts/:id',
    name: 'contact/edit',
    meta: {title: 'Kontakt bearbeiten'},
    component: require('./pages/contact-page').default,
  },

  // Invoices
  {
    path: '/invoices',
    name: 'invoice/list',
    meta: {title: 'Rechnungen'},
    component: require('./pages/invoices-page').default,
  },
  {
    path: '/invoices/new',
    name: 'invoice/new',
    meta: {new: true, title: 'Neue Rechnung erstellen'},
    component: require('./pages/invoice-page').default,
  },
  {
    path: '/invoices/new-from-template/:templateId',
    name: 'invoice/new-from-template',
    meta: {new: true, title: 'Neue Rechnung erstellen'},
    component: require('./pages/invoice-page').default,
  },
  {
    path: '/invoices/:id',
    name: 'invoice/edit',
    meta: {title: 'Rechnung bearbeiten'},
    component: require('./pages/invoice-page').default,
  },

  // Invoice Templates
  {
    path: '/templates',
    name: 'invoice-template/list',
    meta: {title: 'Rechnungsvorlagen'},
    component: require('./pages/invoice-templates-page').default,
  },
  {
    path: '/templates/new',
    name: 'invoice-template/new',
    meta: {new: true, title: 'Neue Rechnungsvorlage erstellen'},
    component: require('./pages/invoice-template-page').default,
  },
  {
    path: '/templates/:id',
    name: 'invoice-template/edit',
    meta: {title: 'Rechnungsvorlage bearbeiten'},
    component: require('./pages/invoice-template-page').default,
  },

  // Invoice Plans
  {
    path: '/plans',
    name: 'invoice-plan/list',
    meta: {title: 'Rechnungspläne'},
    component: require('./pages/invoice-plans-page').default,
  },
  {
    path: '/plans/new',
    name: 'invoice-plan/new',
    meta: {new: true, title: 'Neuen Rechnungsplan anlegen'},
    component: require('./pages/invoice-plan-page').default,
  },
  {
    path: '/plans/:id',
    name: 'invoice-plan/edit',
    meta: {title: 'Rechnungsplan bearbeiten'},
    component: require('./pages/invoice-plan-page').default,
  },

  // Import
  {
    path: '/import',
    name: 'import',
    meta: {title: 'Bankimport'},
    component: require('./pages/import/manual-import-page').default,
  },

  // Reports
  {
    path: '/reports',
    name: 'reports',
    meta: {title: 'Berichte'},
    component: require('./pages/reports-page').default,
  },

  // Settings
  {
    path: '/settings',
    name: 'settings',
    meta: {title: 'Einstellungen', hasTabs: true},
    component: require('./pages/settings-page').default,
    children: [
      {
        path: 'layout/:templateId?',
        name: 'settings/layout',
        meta: {title: 'Einstellungen', hasTabs: true},
        component: require('./pages/settings/layouts-page').default,
      },
      {
        path: 'layout/preview/:templateId?',
        name: 'settings/layout/preview',
        meta: {hideNavigation: true},
        component: require('./pages/settings/layout-preview-page').default,
      },
      {
        path: ':default?',
        name: 'settings/security',
        meta: {title: 'Einstellungen', hasTabs: true},
        component: require('./pages/settings/security-settings-page').default,
      },
    ],
  },

  // Catchall route
  {
    path: '*',
    redirect: {name: 'home'},
    meta: {catchall: true},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

/**
 * The route guard.
 * Checks for login state and permission restrictions
 */
router.beforeEach((to, from, next) => {
  // Ignore the guard when the according
  // meta tag is set:
  if (to.matched.some(route => !route.meta.disableGuard)) {
    if (!store.getters.isLoggedIn) {
      store.commit('setRedirectRoute', to)
      next({name: 'login'})
      return
    }

    next()
  }

  next()
})

export {router}
