import {axios} from '../axios'
import camelcase from 'camelcase'
import {hasOwnProperty} from '@/lib/hasOwnProperty'

export const editPageForResource = resource => {
  const camelCaseResource = camelcase(resource)
  return {
    data: () => ({
      loaded: true,
      loading: false,
      hasPendingChanges: false,
    }),
    watch: {
      $route: {
        immediate: true,
        handler(route) {
          if (route.meta.new) {
            this[camelCaseResource].id = null

            this.$nextTick(() => {
              this.undoredo?.clear()
            })
          } else {
            this[camelCaseResource].id = route.params.id
            this.loaded = false
            this.load()
          }
        }
      },
      [camelCaseResource]: {
        deep: true,
        handler() {
          this.hasPendingChanges = true
        }
      }
    },
    methods: {
      getOriginal(...propertyChain) {
        let value = this[camelCaseResource]._original

        if (!value) return null

        for (const property of propertyChain) {
          if (hasOwnProperty(value, property)) {
            value = value[property]
          } else {
            value = null
            break
          }
        }

        return value
      },
      async load() {
        const {data} = await axios.get(`/${resource}/${this[camelCaseResource].id}`)
        await this.setModel(data)
      },
      async setModel(data) {
        this[camelCaseResource] = {
          ...data,
          _original: data
        }
        this.loaded = true
        await this.$nextTick()
        this.hasPendingChanges = false

        this.undoredo?.clear()
      },
      async save() {
        this.loading = true

        try {
          const {data} = this[camelCaseResource].id
            ? await axios.put(`/${resource}/${this[camelCaseResource].id}`, this[camelCaseResource])
            : await axios.post(`/${resource}`, this[camelCaseResource])
          this.hasPendingChanges = false

          if (this.$route.meta.new) {
            this.$router.replace({
              name: `${resource}/edit`,
              params: {
                id: data.id
              }
            })
          } else {
            await this.setModel(data)
          }
        } catch (e) {
          console.error(e)
        }

        this.loading = false
      },
    }
  }
}
