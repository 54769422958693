<template>
  <div v-if="loaded">
    <v-card outlined class="upcoming-invoice-plans-card">
      <v-card-title class="pb-1">Rechnungspläne</v-card-title>
      <template v-if="upcomingPlanGroups.length === 0">
        <v-card-text>
          In den nächsten sieben Tagen werden keine Rechnungsentwürfe durch Rechnungspläne erstellt.
        </v-card-text>
        <v-card-text>
          <div class="d-flex">
            <v-btn depressed :to="{name: 'invoice-plan/new'}" class="primary--text hidden-xs-only">
              <v-icon left>mdi-plus</v-icon>
              Neuer Plan
            </v-btn>
            <v-spacer />
            <v-btn text :to="{name: 'invoice-plan/list'}">
              Alle Pläne
            </v-btn>
          </div>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text>
          Geplante Rechnungsentwürfe für die nächsten sieben Tage:
        </v-card-text>
        <v-list class="pa-0">
          <template v-for="group in upcomingPlanGroups">
            <v-subheader :key="group.date">{{ formatDate(group.date, 'EEEE') }}, {{ formatDateRelative(group.date) }}</v-subheader>
            <v-divider :key="group.date + 'divider'" />
            <v-list-item
              v-for="plan in group.invoice_plans"
              :key="group.date + plan.id"
              :to="{name: 'invoice-plan/edit', params: {id: plan.id}}"
              class="py-3"
            >
              <invoice-plan-summary-view :plan="plan" class="flex-grow-1" />
            </v-list-item>
          </template>
        </v-list>
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn depressed :to="{name: 'invoice-plan/list'}">
              Alle anzeigen
            </v-btn>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
  import {axios} from '../lib/axios'
  import {formatsDates} from '../lib/mixins/formatsDates'
  import InvoicePlanSummaryView from './invoice-plan-summary-view'

  export default {
    name: 'upcoming-invoice-plans-card',
    components: {InvoicePlanSummaryView},
    mixins: [formatsDates],
    data: () => ({
      upcomingPlanGroups: [],
      loaded: false,
    }),
    mounted() {
      this.fetch()
    },
    methods: {
      async fetch() {
        const {data} = await axios.get('/invoice-plan/upcoming')

        this.upcomingPlanGroups = data.sort((a, b) => a.date < b.date ? -1 : 1)
        this.loaded = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .upcoming-invoice-plans-card {
    height: 100%;
    width: 100%;
  }
</style>
