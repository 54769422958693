<template>
  <pre v-if="visible" class="debug-view my-2">
    <slot />
  </pre>
</template>

<script>
  export default {
    name: 'debug-view',
    data: () => ({
      visible: false,
      streak: 0,
    }),
    mounted() {
      document.addEventListener('keydown', this.onKeyDown)
    },
    destroyed() {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
      onKeyDown(event) {
        event.key === 'Control' ? this.streak++ : this.streak = 0;
        if (this.streak === 3) {
          this.visible = !this.visible
          this.streak = 0
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .debug-view {
    white-space: normal;
    padding: 8px;
    background-color: transparentize(black, 0.9);
    border: 2px solid transparentize(black, 0.9);
    border-radius: 8px;
  }
</style>
