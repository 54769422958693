<template>
  <div class="d-flex align-center invoice-summary-view">
    <div>
      <div class="overline">
        {{ invoice.reference ? `#${invoice.reference}` : 'Entwurf' }}
      </div>
      <div class="title">{{ invoice.contact.full_name || invoice.contact.company }}</div>
      <div class="body-2" v-if="!!invoice.contact.full_name">
        {{ invoice.contact.company }}
      </div>
    </div>
    <v-spacer />
    <div class="text-right pl-2">
      <div class="body-2 semi-transparent">{{ formatDateRelativeDays(invoice[date]) }} ({{ formatDate(invoice[date], 'E') }})</div>
      <div class="body-1">{{ formatAmount(invoice.total_price) }}</div>
    </div>
  </div>
</template>

<script>
  import {formatsAmounts} from '../lib/mixins/formatsAmounts'
  import {formatsDates} from '../lib/mixins/formatsDates'

  export default {
    name: 'invoice-summary-view',
    mixins: [formatsAmounts, formatsDates],
    props: {
      invoice: Object,
      date: {
        type: String,
        default: 'invoiced_at',
      }
    }
  }
</script>

<style lang="scss" scoped>
  .invoice-summary-view {
    * {
      line-height: 1.2 !important;
    }

    .semi-transparent {
      opacity: 0.6;
    }
  }
</style>
