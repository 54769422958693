import {axios} from '../axios'
import camelcase from 'camelcase'

export const listPageForResource = (resource, container = null, plural = resource + 's') => {
  const camelCasePlural = camelcase(plural)

  return {
    data: () => ({
      loading: false,
    }),
    computed: {
      [camelCasePlural]() {
        const cache = this.$store.state.modelCache[resource]

        if (!cache) {
          return []
        }

        if (container) {
          return container.wrap(cache)
        }

        return cache
      },
    },
    mounted() {
      this.load()
    },
    methods: {
      async load() {
        this.loading = true

        const {data} = await axios.get(`/${resource}`)

        this.$store.commit('updateModelCache', {
          resourceName: resource,
          data,
        })

        this.loading = false
      },
      edit(entity) {
        this.$router.push({name: `${resource}/edit`, params: {id: entity.id}})
      },
    },
  }
}
