<template>
  <v-responsive :aspect-ratio="85.6/53.98" max-width="400" class="margin-center">
    <v-card class="fill-height">
      <div class="pa-8 fill-height credit-card d-flex flex-column credit-card-border-radius">
        <div class="d-flex">
          <div>
            <div>
              <svg class="logo-card" viewBox="0 0 94.01 49.36" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.04 15.4l17.94 26.49h3.29V7.37h-3.99v26.25L3.91 8.02l-.44-.65H1.59l-.03-.04v.04H.05v34.52h3.99V15.4zm47.51 12.68C57.62 24.4 60 21.24 60 16.79c0-6.11-4.13-10.06-10.5-10.06A12.14 12.14 0 0 0 38.38 14l-.6 1.34L41.39 17l.61-1.29a8.15 8.15 0 0 1 7.48-5c4 0 6.45 2.28 6.45 6.11 0 2.46-1.07 4.64-6.37 7.9-9.46 5.8-11.55 8.39-11.8 14.6v2.6h22.13v-4h-18c.36-3.18 1.76-5.03 9.66-9.84zm30.95-8.64a14.06 14.06 0 0 0-8.22 3c.62-7.44 3.9-11.8 9-11.8a8.4 8.4 0 0 1 6.07 2.41l1.14 1L93.08 11l-1.14-1a12.61 12.61 0 0 0-8.67-3.29c-8.07 0-13.08 7.07-13.08 18.45 0 12.79 6.34 17.35 12.27 17.35C90 42.53 94 36.55 94 30.64c0-8.27-6.19-11.2-11.5-11.2zm7.5 11.2c0 3.95-2.58 7.94-7.51 7.94-3.35 0-7.47-2.85-8.2-10.85 1.45-1.74 4.58-4.35 8.24-4.35 6.71.01 7.47 5.08 7.47 7.26zM0 46.39h25.32v2.97H0zM.09 0h25.23v2.97H.09z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div>
              <strong>{{ connection.username }}</strong>
            </div>
          </div>
          <v-spacer />
          <svg class="logo-card" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 152.407 108"><path fill="none" d="M0 0h152.407v108H0z"/><path fill="#ff5f00" d="M60.412 25.697h31.5v56.606h-31.5z"/><path d="M62.412 54a35.938 35.938 0 0113.75-28.303 36 36 0 100 56.606A35.938 35.938 0 0162.412 54z" fill="#eb001b"/><path d="M134.407 54a35.999 35.999 0 01-58.245 28.303 36.005 36.005 0 000-56.606A35.999 35.999 0 01134.407 54zM130.972 76.308v-1.16h.468v-.235h-1.19v.236h.467v1.159zm2.31 0V74.91h-.364l-.42.962-.42-.962h-.364v1.398h.257v-1.054l.394.908h.267l.394-.91v1.056z" fill="#f79e1b"/></svg>
        </div>
        <v-spacer />
        <div class="d-flex align-end">
          <v-btn class="ma-0" small icon @click="$emit('delete')">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn :loading="loading" color="primary" depressed @click="$emit('import')">
            Importieren
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-responsive>
</template>

<script>
  export default {
    name: 'bank-connection-view',
    props: {
      connection: Object,
      loading: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>

<style lang="scss" scoped>
  .n26-logo {
    display: block;
    margin: 0 auto;
    max-width: 128px;
  }

  .margin-center {
    margin: 0 auto;
  }

  .credit-card {
    position: relative;
  }

  .logo-card {
    width: 25%;
    border-radius: 0;
  }

  .credit-card-border-radius {
    border-radius: 12px;
  }
</style>
