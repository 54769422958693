<template>
  <v-container>
    <v-card class="mb-2">
      <v-card-title>Passwort ändern</v-card-title>
      <v-card-text>
        <div class="mb-5">
          Wähle ein sicheres Passwort für dein Konto.
        </div>

        <v-form class="password-change-form">
          <v-text-field type="password" v-model="oldPassword" hide-details label="Derzeitiges Passwort" :error-messages="changePasswordOldPasswordInvalid ? ['Das Passwort ist falsch'] : null" />
          <v-text-field type="password" v-model="newPassword" hide-details label="Neues Passwort" />
          <v-text-field type="password" v-model="newPasswordRepeat" label="Neues Passwort wiederholen" />
        </v-form>

        <v-btn
          :disabled="!passwordChangeIsValid"
          :outlined="!passwordChangeIsValid"
          :text="!passwordChangeIsValid"
          color="primary"
          @click="changePassword"
          :loading="changePasswordLoading"
        >
          <template v-if="changePasswordDone">
            <v-icon left>mdi-check</v-icon>
            Geändert
          </template>
          <template v-else>
            Passwort ändern
          </template>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Zwei-Faktor-Authentifizierung (2FA)</v-card-title>
      <v-card-text>
        <template v-if="$store.state.user.totp_enabled">
          <div class="mb-5">
            Die Zwei-Faktor-Authentifizierung ist für dein Konto aktiviert.
          </div>

          <v-btn large text outlined color="primary" @click="totpDisableDialogOpen = true">
            <v-icon left>
              mdi-shield-off
            </v-icon>
            2FA deaktivieren
          </v-btn>
        </template>
        <template v-else>
          <div class="mb-5">
            Die Zwei-Faktor-Authentifizierung ist derzeit deaktiviert. Aktiviere diese zusätzliche Sicherheitsüberprüfung
            bei der Anmeldung, um dein Konto zu schützen.
          </div>

          <v-btn large text outlined color="primary" @click="setupTotp">
            <v-icon left>
              mdi-security
            </v-icon>
            2FA aktivieren
          </v-btn>
        </template>

        <v-dialog v-model="totpSetupDialogOpen" max-width="450">
          <v-card>
            <v-card-title>2FA-Einrichtung abschließen</v-card-title>
            <v-card-text>
              <p>
                Scanne nun mit deiner 2FA-App diesen QR-Code oder gib den angezeigten Schlüssel manuell ein.
                Gib anschließend den in deiner App angezeigten Code zur Verifizierung ein.
              </p>
              <div class="d-flex flex-column align-center mb-5">
                <v-card outlined>
                  <qrcode class="qrcode" :value="totpQrUrl" />
                </v-card>
                <div class="mt-2 secret">{{ totpSecret }}</div>
              </div>
              <v-text-field
                :autofocus="!$app.isTouch"
                outlined
                :error-messages="totpKeyInvalid ? ['Bitte überprüfe den Code'] : null"
                label="Code"
                v-model="totpKey"
                @keydown.enter="enableTotp"
              />
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="enableTotp">
                  2FA aktivieren
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="totpDisableDialogOpen" max-width="450">
          <v-card>
            <v-card-title>2FA deaktivieren</v-card-title>
            <v-card-text>
              <p>
                Du bist gerade dabei, 2FA für dein Konto zu deaktivieren.
                Dein Konto ist dann nicht mehr durch einen zusätzlichen Anmeldefaktor geschützt und kann von jedem
                benutzt werden, der dein Passwort kennt.
                Bitte gib zur Bestätigung den aktuellen 2FA-Code ein.
              </p>
              <v-text-field
                :autofocus="!$app.isTouch"
                outlined
                :error-messages="totpKeyInvalid ? ['Bitte überprüfe den Code'] : null"
                label="Code"
                v-model="totpKey"
                @keydown.enter="disableTotp"
              />
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="disableTotp">
                  2FA deaktivieren
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="changePasswordTotpDialogOpen" max-width="450">
          <v-card>
            <v-card-title>2FA Bestätigung</v-card-title>
            <v-card-text>
              <p>
                Du bist gerade dabei, dein Passwort zu ändern.
                Bitte verifiziere diese Aktion durch Eingabe des aktuellen 2FA-Codes.
              </p>
              <v-text-field
                :autofocus="!$app.isTouch"
                outlined
                :error-messages="changePasswordTotpKeyInvalid ? ['Bitte überprüfe den Code'] : null"
                label="Code"
                v-model="changePasswordTotpKey"
                @keydown.enter="changePassword"
              />
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="changePassword">
                  Passwort ändern
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {axios} from '../../lib/axios'
  import Qrcode from '@chenfengyuan/vue-qrcode'

  export default {
    name: 'security-settings-page',
    components: {
      Qrcode
    },
    data: () => ({
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      totpSetupDialogOpen: false,
      totpLoading: false,
      totpSecret: '',
      totpQrUrl: '',
      totpKey: '',
      totpKeyInvalid: false,
      totpDisableDialogOpen: false,
      changePasswordLoading: false,
      changePasswordDone: false,
      changePasswordOldPasswordInvalid: false,
      changePasswordTotpKey: '',
      changePasswordTotpKeyInvalid: false,
      changePasswordTotpDialogOpen: false,
    }),
    computed: {
      passwordChangeIsValid() {
        return !!this.oldPassword && !!this.newPassword && this.newPassword === this.newPasswordRepeat
      }
    },
    methods: {
      async setupTotp() {
        const {data} = await axios.post('/user/totp/setup')
        this.totpQrUrl = data.totp_qr_url
        this.totpSecret = data.totp_secret
        this.totpSetupDialogOpen = true
      },
      async enableTotp() {
        try {
          const {data} = await axios.post('/user/totp/enable', {
            key: this.totpKey
          })
          this.$store.commit('setUser', data.user)
          this.totpSetupDialogOpen = false
          this.totpKeyInvalid = false
          this.totpKey = ''
        } catch (e) {
          this.totpKeyInvalid = true
        }
      },
      async disableTotp() {
        try {
          const {data} = await axios.post('/user/totp/disable', {
            key: this.totpKey
          })
          this.$store.commit('setUser', data.user)
          this.totpDisableDialogOpen = false
          this.totpKeyInvalid = false
          this.totpKey = ''
        } catch (e) {
          this.totpKeyInvalid = true
        }
      },
      async changePassword() {
        this.changePasswordLoading = true

        this.changePasswordTotpKeyInvalid = false
        this.changePasswordOldPasswordInvalid = false

        try {
          await axios.post('/user/password', {
            old: this.oldPassword,
            new: this.newPassword,
            totp_key: this.changePasswordTotpKey,
          })

          this.changePasswordTotpKey = ''
          this.oldPassword = ''
          this.newPassword = ''
          this.newPasswordRepeat = ''
          this.changePasswordDone = true
          this.changePasswordTotpDialogOpen = false
        } catch (e) {
          switch (e.response?.headers?.['x-taco-error']) {
            case 'totp_required':
              this.changePasswordTotpDialogOpen = true
              break
            case 'totp_invalid':
              this.changePasswordTotpDialogOpen = true // Just in case
              this.changePasswordTotpKeyInvalid = true
              break
            case 'old_password_invalid':
              this.changePasswordOldPasswordInvalid = true
              break
          }

          console.error(e)
        }

        this.changePasswordLoading = false
      }
    },
  }
</script>

<style lang="scss" scoped>
  .password-change-form {
    max-width: 300px;
  }

  .secret {
    font-family: 'Roboto Mono', monospace;
    text-align: center;
    word-break: break-all;
  }

  .qrcode {
    display: block;
    /* Override component defaults */
    height: auto !important;
    width: unset !important;
    max-width: 100% !important;
  }
</style>
