<template>
  <div class="pa-5 fill-height">
    <div class="layout-editor-container">
      <div class="d-flex align-center pb-2">
        <template v-if="!!templateId">
          <v-select
            class="flex-shrink-1 flex-grow-0"
            v-model="templateId"
            placeholder="Layout auswählen"
            hide-details
            dense
            :items="[
              {text: 'Rechnung (invoice.html)', value: 'invoice.html'},
              {text: 'Mail: Neue Rechnung, HTML (invoice_mail.html)', value: 'invoice_mail.html'},
              {text: 'Mail: Neue Rechnung, Text (invoice_mail.text)', value: 'invoice_mail.text'},
              {text: 'Mail: Zahlungserinnerung, HTML (reminder_mail.html)', value: 'reminder_mail.html'},
              {text: 'Mail: Zahlungserinnerung, Text (reminder_mail.text)', value: 'reminder_mail.text'},
              {text: 'Mail: Stornierung, HTML (cancellation_mail.html)', value: 'cancellation_mail.html'},
              {text: 'Mail: Stornierung, Text (cancellation_mail.text)', value: 'cancellation_mail.text'},
            ]"
          />
          <v-spacer />
          <v-btn class="mr-2" @click="openLivePreview">
            Live-Vorschau
          </v-btn>
          <v-btn color="primary" @click="saveCurrentLayout" :disabled="!hasUnsavedChanges">
            <template v-if="hasUnsavedChanges">Speichern</template>
            <template v-else>
              <v-icon left>mdi-check</v-icon>
              Gespeichert
            </template>
          </v-btn>
        </template>
        <div class="layout-cards-container" v-else>
          <v-card class="d-flex flex-column" outlined>
            <v-card-title>Rechnung</v-card-title>
            <v-card-text>Diese Vorlage wird als als PDF an den Kunden versandt. Für eine korrekte Vorschau bitte einen Chromium basierten Browser verwenden.</v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-btn text @click="templateId = 'invoice.html'">
                <v-icon left>mdi-pencil</v-icon>
                HTML
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="d-flex flex-column" outlined>
            <v-card-title>Mail - Neue Rechnung</v-card-title>
            <v-card-text>
              Vorlage für eine E-Mail, die den Kunden über eine neue Rechnung informiert.
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-btn text @click="templateId = 'invoice_mail.html'">
                <v-icon left>mdi-pencil</v-icon>
                HTML
              </v-btn>
              <v-btn text @click="templateId = 'invoice_mail.text'">
                <v-icon left>mdi-pencil</v-icon>
                Text
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="d-flex flex-column" outlined>
            <v-card-title>Mail - Zahlungserinnerung</v-card-title>
            <v-card-text>
              Vorlage für eine E-Mail, die den Kunden über eine überfällige Rechung informiert.
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-btn text @click="templateId = 'reminder_mail.html'">
                <v-icon left>mdi-pencil</v-icon>
                HTML
              </v-btn>
              <v-btn text @click="templateId = 'reminder_mail.text'">
                <v-icon left>mdi-pencil</v-icon>
                Text
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="d-flex flex-column" outlined>
            <v-card-title>Mail - Stornierung</v-card-title>
            <v-card-text>
              Vorlage für eine E-Mail, die den Kunden über die Stornierung einer Rechung informiert.
            </v-card-text>
            <v-spacer />
            <v-card-actions>
              <v-btn text @click="templateId = 'cancellation_mail.html'">
                <v-icon left>mdi-pencil</v-icon>
                HTML
              </v-btn>
              <v-btn text @click="templateId = 'cancellation_mail.text'">
                <v-icon left>mdi-pencil</v-icon>
                Text
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <template v-if="!!templateId">
        <v-alert dismissible dense class="caption" v-if="displayMailHTMLWarning">
          Es werden nicht alle HTML-Elemente unterstützt. <a class="d-inline" target="_blank" rel="noreferrer noopener" href="https://caniemail.com">Weitere Informationen</a>
        </v-alert>
        <monaco-editor
          class="flex-grow-1"
          v-model="template"
          :options="{
            automaticLayout: true,
            theme: 'vs-dark',
            language: 'twig',
          }"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import {axios} from '@/lib/axios'

  export default {
    name: 'layouts-page',
    components: {
      MonacoEditor: () => import('../../components/monaco-editor'),
    },
    data: () => ({
      templateId: null,
      template: '',
      hasUnsavedChanges: false,
    }),
    computed: {
      displayMailHTMLWarning() {
        return [
          'invoice_mail.html',
          'reminder_mail.html',
          'cancellation_mail.html',
        ].includes(this.templateId)
      },
    },
    watch: {
      templateId(templateId) {
        if (templateId !== this.$route.params.templateId) {
          this.$router.push({
            name: 'settings/layout',
            params: {templateId},
          })
        }

        if (templateId) {
          this.fetchLayoutTemplate()
        }
      },
      template() {
        this.hasUnsavedChanges = true
      },
      '$route.params.templateId': {
        immediate: true,
        handler(templateId) {
          this.templateId = templateId
        }
      }
    },
    methods: {
      async fetchLayoutTemplate() {
        const {data} = await axios.get(`/layout/template/${this.templateId}`)
        this.template = data
        this.updatePreview()
        await this.$nextTick()
        this.hasUnsavedChanges = false
      },
      async saveCurrentLayout() {
        await axios.post(`/layout/template/${this.templateId}`, {
          template: this.template,
        })
        this.hasUnsavedChanges = false
        this.updatePreview()
      },
      updatePreview() {
        if (this.livePreviewWindow) {
          this.livePreviewWindow.postMessage({
            type: 'updatePreview',
            templateId: this.templateId,
          }, location.origin)
        }
      },
      openLivePreview() {
        this.livePreviewWindow = window.open(
          this.$router.resolve(
            {
              name: 'settings/layout/preview',
              params: {
                templateId: this.templateId
              },
            },
          ).href,
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .layout-editor-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .layout-cards-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
  }

  .preview-frame {
    width: 100%;
    background: white;
    border: none;
  }
</style>
