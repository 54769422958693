<template>
  <a @click="download" class="pa-2 d-inline-flex align-center white--text">
    <v-icon class="mr-2">mdi-file</v-icon>
    <div class="filename">
      <div>{{ invoiceAttachment.filename }}</div>
      <v-expand-transition>
        <div v-if="loading">
          <div class="pt-1">
            <v-progress-linear indeterminate />
          </div>
        </div>
      </v-expand-transition>
    </div>
    <v-btn v-if="editable" class="ml-3" icon @click.stop="$emit('delete')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </a>
</template>

<script>
  import {axios} from '@/lib/axios'
  import {downloadBlob} from '@/lib/downloadBlob'

  export default {
    name: 'invoice-attachment-view',
    props: ['invoiceAttachment', 'editable'],
    data: () => ({
      loading: false,
    }),
    methods: {
      async download() {
        this.loading = true

        try {
          const {data} = await axios.get(`/invoice/${this.invoiceAttachment.invoice_id}/attachment/${this.invoiceAttachment.id}/download`, {
            responseType: 'blob',
          })

          console.log(this.invoiceAttachment)
          downloadBlob(data, this.invoiceAttachment.filename, this.invoiceAttachment.mime)
        } catch (e) {
          console.error(e)
        }

        this.loading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .filename {
    line-height: 1;
  }
</style>
