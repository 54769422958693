<template>
  <div class="fill-height">
    <portal to="tabs">
      <v-tabs>
        <v-tab
          v-for="tab in tabs"
          :key="tab.route"
          :to="{name: tab.route}"
          :exact="tab.exact"
        >
          <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </portal>
    <transition name="page-transition" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'settings-page',
    data: () => ({
      tabs: [
        {name: 'Sicherheit', exact: true, icon: 'mdi-lock-outline', route: 'settings/security'},
        {name: 'Layout', exact: false, icon: 'mdi-pencil-ruler', route: 'settings/layout'},
      ],
    }),
  }
</script>

<style scoped>

</style>
