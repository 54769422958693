<template>
  <v-container v-if="loaded">
    <v-card :disabled="loading" class="pa-3">
      <v-row dense>
        <v-col cols="12" sm="6" md="4">
          <contact-select label="Kunde" v-model="invoicePlan.contact" />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <invoice-template-select label="Vorlage" v-model="invoicePlan.invoice_template" />
        </v-col>
        <v-col cols="12" md="4">
          <v-checkbox v-model="invoicePlan.enabled" label="Plan aktiviert" />
        </v-col>

        <v-col cols="12" sm="4" md="2">
          <v-text-field v-model="invoicePlan.cron_expression" label="Intervall (Cron)" />
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-menu
            :close-on-content-click="false"
            min-width="0"
            transition="scale-transition"
          >
            <template v-slot:activator="{on}">
              <v-text-field
                :value="formatDate(invoicePlan.start_date)"
                label="Plan starten ab (inkl.)"
                readonly
                v-on="on"
              />
            </template>
            <template v-slot:default>
              <v-date-picker v-model="invoicePlan.start_date" />
            </template>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-text-field type="number" v-model="invoicePlan.schedule_days_before" label="Entwurf anlegen" suffix="Tag(e) vorher" />
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="!!cronDescription">
            <div>
              Ausführung: {{ cronDescription.description }}
            </div>
            <div>
              Nächste drei Zeitpunkte: {{ cronDescription.next_dates.join(', ') }}
            </div>
          </template>
          <v-alert color="error" v-else-if="cronDescription === false">
            Ungültige Zeitangaben
          </v-alert>
        </v-col>
      </v-row>
    </v-card>

    <v-btn color="primary" @click="save" :disabled="!hasPendingChanges">
      <template v-if="hasPendingChanges">Speichern</template>
      <template v-else>
        <v-icon left>mdi-check</v-icon>
        Gespeichert
      </template>
    </v-btn>

    <debug-view>
      <pre>{{ invoicePlan }}</pre>
    </debug-view>
  </v-container>
  <throttled-spinner-container v-else />
</template>

<script>
  import DebugView from '../components/debug-view'
  import ThrottledSpinnerContainer from '../components/throttled-spinner-container'
  import {format, formatISO, startOfToday} from 'date-fns'
  import {editPageForResource} from '../lib/mixins/editPage'
  import ContactSelect from '../components/contact-select'
  import InvoiceTemplateSelect from '../components/invoice-template-select'
  import {axios} from '../lib/axios'

  const createInvoicePlan = () => ({
    id: null,
    contact: null,
    invoice_template: null,
    enabled: true,
    start_date: formatISO(startOfToday(), {representation: 'date'}),
    cron_expression: '1 * *',
    schedule_days_before: 1
  })

  export default {
    name: 'invoice-plan-page',
    components: {InvoiceTemplateSelect, ContactSelect, ThrottledSpinnerContainer, DebugView},
    mixins: [editPageForResource('invoice-plan')],
    data: () => ({
      invoicePlan: createInvoicePlan(),
      attachmentFile: null,
      cronDescription: null,
    }),
    watch: {
      'invoicePlan.cron_expression'() {
        this.updateCronDescription()
      },
      'invoicePlan.start_date'() {
        this.updateCronDescription()
      },
    },
    mounted() {
      this.updateCronDescription()
    },
    methods: {
      formatDate(date) {
        if (!date) return ''
        return format(new Date(date), 'dd.MM.yyyy')
      },
      updateCronDescription() {
        if (!!this.invoicePlan.cron_expression && !!this.invoicePlan.start_date) {
          this.fetchCronDescription()
        }
      },
      async fetchCronDescription() {
        try {
          const {data} = await axios.get(`/invoice-plan/cron`, {
            params: {
              expression: this.invoicePlan.cron_expression,
              start_date: this.invoicePlan.start_date,
            }
          })
          this.cronDescription = data
        } catch (e) {
          this.cronDescription = false
          console.error(e)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
