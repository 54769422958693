var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.invoices,"headers":[
      {text: '#', value: 'reference'},
      {text: 'Status', value: 'status'},
      {text: 'Kunde', value: 'contact.display_name'},
      {text: 'Gesamtbetrag', value: 'total_price'},
      {text: 'Rechnungsdatum', value: 'invoiced_at'},
      {text: 'Fällig bis', value: 'due_at'},
    ],"options":{
      sortBy: ['invoiced_at'],
      sortDesc: [true],
    }},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.total_price",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatAmount(item.total_price))+" ")]}},{key:"item.status",fn:function({item}){return [_c('v-chip',{attrs:{"small":"","outlined":"","color":item.status_color}},[_vm._v(_vm._s(item.status))])]}}])}),_c('v-btn',{attrs:{"to":{name: 'invoice/new'}}},[_vm._v("Hinzufügen")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }