<template>
  <iframe class="fill-height preview-frame" ref="previewFrame" />
</template>

<script>
  import {axios} from '../../lib/axios'

  export default {
    name: 'layout-preview-page',
    watch: {
      '$route.params.templateId': {
        immediate: true,
        handler(templateId) {
          if (templateId) {
            this.updatePreview(templateId)
          }
        }
      }
    },
    mounted() {
      window.addEventListener('message', ({data, origin}) => {
        if (origin !== location.origin) {
          return
        }

        if (data.type === 'updatePreview') {
          if (this.$route.params.templateId !== data.templateId) {
            this.$router.replace({
              params: {
                templateId: data.templateId,
              },
            })
          } else {
            this.updatePreview(data.templateId)
          }
        }
      })

      if (window.parent) {
        window.parent.addEventListener('close', () => window.close())
      }
    },
    methods: {
      async updatePreview(templateId) {
        let response = null

        try {
          response = await axios.get(`/layout/template/${templateId}/preview`)
        } catch (e) {
          response = e.response
        }

        this.$refs.previewFrame.src = `data:${response.headers['content-type'].split(';')[0]},${escape(response.data)}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .layout-editor-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .preview-frame {
    width: 100%;
    background: white;
    border: none;
  }
</style>
