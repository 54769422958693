export const formatsAmounts = {
  methods: {
    formatAmount(amount) {
      return Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      }).format(amount)
    }
  }
}
