import Vue from 'vue'
import Vuex from 'vuex'
import {axios} from './lib/axios'
import {hasOwnProperty} from './lib/hasOwnProperty'
import Cookie from 'js-cookie'
import {router} from './router'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    loggingIn: false,
    sessionToken: null,
    user: null,
    business: null,
    redirectRoute: null,
    modelCache: {},
  },
  mutations: {
    setSessionToken(state, sessionToken) {
      state.sessionToken = sessionToken

      if (sessionToken === null) {
        hasOwnProperty(axios.defaults.headers, 'Authorization') && delete axios.defaults.headers['Authorization']
        Cookie.remove('token')
      } else {
        axios.defaults.headers['Authorization'] = `Bearer ${sessionToken}`
        Cookie.set('token', sessionToken, {expires: 90})
      }
    },
    setUser(state, user) {
      state.user = user
    },
    setBusiness(state, business) {
      state.business = business
    },
    setLoggingIn(state, loggingIn) {
      state.loggingIn = loggingIn
    },
    setRedirectRoute(state, redirectRoute) {
      state.redirectRoute = redirectRoute
    },
    updateModelCache(state, {resourceName, data}) {
      Vue.set(state.modelCache, resourceName, data)
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.sessionToken !== null && state.user !== null
    },
  },
})

const savedToken = Cookie.get('token')

if (savedToken) {
  store.commit('setLoggingIn', true)

  ;(async () => {
    try {
      const {data} = await axios.post('/auth/session/verify', {
        token: savedToken
      })

      store.commit('setSessionToken', savedToken)
      store.commit('setUser', data.user)
      store.commit('setBusiness', data.business)
    } catch (e) {
      store.commit('setSessionToken', null)
      store.commit('setUser', null)
      store.commit('setBusiness', null)

      if (router.currentRoute.name !== 'login') {
        store.commit('setRedirectRoute', router.currentRoute)
      }

      console.error(e)
    }

    store.commit('setLoggingIn', false)
  })()
}
